














import { Component, Prop } from "vue-property-decorator";
import { ReferralsRawData } from "@/views/chat/statistics/logic/usersStatTypes";
import { ChartParams } from "@/views/chat/statistics/logic/types";
import { UseFields } from "piramis-base-components/src/components/Pi";
import { ApexLocales } from "@/views/chat/statistics/logic/defaultApexSettings";
import VueApexCharts from "vue-apexcharts";

@Component({
  components: {
    VueApexCharts
  }
})
export default class TopReferrals extends UseFields {
  @Prop() rawData!: ReferralsRawData

  referrals: ChartParams | null = null

  mounted(): void {
    let users = [ ...this.rawData.users ]
    const botIndex = users.findIndex(item => !item.user_id)
    const keeperIndex = users.findIndex(item => !item.user_name || !item.user_name.length)

    users.splice(botIndex, 1)
    users.splice(keeperIndex, 1)

    const allReferrals = this.rawData.users.map(item => item.referrals).reduce((a, b) => a + b)
    const refPercent = allReferrals / 100

    const sortUsers = users.sort((a, b) => b.referrals - a.referrals)
    const top = sortUsers.slice(0, 5)

    this.referrals = {
      series: [ {
        data: top.map(item => {
          return Number((item.referrals / refPercent).toFixed(2))
        })
      }
      ],
      options: this.getOptions(top.map(item => item.user_name ? item.user_name : item.user_login))
    }
  }

  getOptions(labels: Array<string>): any {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexLocales
      },
      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 4,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      legend: {
        position: 'bottom',
        formatter: (label: string, obj: any) => {
          const value = obj.w.config.series[0].data[obj.seriesIndex]
          return `${ label }: ${ value }%`
        }
      },
      labels: labels,
      responsive: [
        {
          breakpoint: 991,
          options: {
            chart: {
              toolbar: {
                show: true,
                tools: {
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true,
                  download: false
                }
              },
              height: 300
            }
          }
        }
      ],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        max: 100,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: true,
          formatter: (d: string) => {
            return `${ d }%`
          }
        }
      },
      grid: {
        show: true
      },
      tooltip: {
        theme: "dark",
        x: {
          title: {
            formatter: function () {
              return '';
            },
          },
          formatter: (val: string) => {
            return ''
          }
        },
        y: {
          title: {
            formatter: function () {
              return '';
            },
          },
          formatter: function (val: string) {
            return val + "%";
          },
        },
      },
    }
  }
}
