










































































import StatisticsHelpers from "@/views/chat/statistics/logic/statisticsHelpers";
import Api from "@/includes/logic/Api";
import { ReferralsRawData } from "@/views/chat/statistics/logic/usersStatTypes";
import PageTitle from '@/components/PageTitle.vue'
import ReferralsTable from "@/views/chat/statistics/components/referrals/referralsTable.vue";
import ReferralsLogins from "./components/referrals/referralsLogins.vue";
import ReferralsLocales from "./components/referrals/referralsLocales.vue";
import TopReferrals from "@/views/chat/statistics/components/referrals/topReferrals.vue";
import { errorNotification } from '@/includes/NotificationService'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'

import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";

import { Component, Mixins } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  components: {
    TopReferrals,
    ReferralsLocales,
    ReferralsLogins,
    PageTitle,
    TableExportButtons,
    ReferralsTable,
    NotAvailableOptionsOverlay
  },
  data() {
    return {
      moment,
      EntityTypes
    }
  }
})
export default class StatisticsReferrals extends Mixins<StatisticsHelpers, TariffsTagsHelper>(StatisticsHelpers, TariffsTagsHelper) {

  rawData: ReferralsRawData | null = null

  loaded: boolean = false

  referrals: any = null

  getStat(): void {
    if (!this.botIsNotAdmin(this.$store.state.chatState.chat)) {
      const chat_id = this.$store.state.chatState.chat.chat_id
      this.loaded = true

      Api.getChatReferralsStat(chat_id).then(res => {
        this.rawData = res.data
        this.referrals = this.rawData!.users.sort((a, b) => b.referrals - a.referrals)
      }).catch((error) => {
        errorNotification(error.response ?? error.message)
      })
        .finally(() => {
          this.loaded = false
        })
    }
  }

  mounted() {
    this.$baseTemplate.saveButton.hide();

    if (!this.getTagsByFieldKey('stat_referrals')) {
      this.getStat()
    }
  }

  destroyed() {
    this.$baseTemplate.saveButton.show();
  }
}
