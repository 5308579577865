















import { Component, Prop } from "vue-property-decorator";
import { UseFields } from "piramis-base-components/src/components/Pi";

import Ratio from "piramis-base-components/src/components/Charts/ratio.vue"
import { ReferralsRawData } from "@/views/chat/statistics/logic/usersStatTypes";
import { RatioParams } from "@/views/chat/statistics/logic/types";

@Component({
  components: {
    Ratio
  }
})
export default class ReferralsLogins extends UseFields {
  @Prop() rawData!: ReferralsRawData

  data: RatioParams | null = null

  mounted(): void {
    let haveLogin = 0
    let noLogin = 0

    this.rawData.users.forEach(user => {
      user.referral_users.forEach(referral => {
        if (referral.user_login) {
          haveLogin ++
        } else {
          noLogin ++
        }
      })
    })

    this.data = {
      series: [
        {
          name: this.$t('undefined').toString(),
          color: 'var(--a-danger)',
          data: noLogin
        },
        {
          name: this.$t('exists').toString(),
          color: 'var(--a-success)',
          data: haveLogin
        },
      ]
    }
  }
}
