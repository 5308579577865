














import { Component, Prop } from "vue-property-decorator";
import { UseFields } from "piramis-base-components/src/components/Pi";
import { ReferralsRawData } from "@/views/chat/statistics/logic/usersStatTypes";
import { ApexLocales } from "@/views/chat/statistics/logic/defaultApexSettings";
import VueApexCharts from "vue-apexcharts";
import { ChartParams } from "@/views/chat/statistics/logic/types";

@Component({
  components: {
    VueApexCharts
  }
})
export default class UsersLocale extends UseFields {
  @Prop() rawData!: ReferralsRawData

  locales: ChartParams | null = null

  mounted(): void {
    const referralsLang: Array<string> = []

    this.rawData.users.forEach(user => {
      referralsLang.push(user.user_lang)
      user.referral_users.forEach(ref => {
        referralsLang.push(ref.user_lang)
      })
    })

    let langsCounts: { [key: string]: number } = {}
    let notSetLang = 0

    referralsLang.forEach(lang => {
      if (!lang || lang === '') {
        notSetLang++
      } else {
        if (langsCounts[lang]) {
          langsCounts[lang]++
        } else {
          langsCounts[lang] = 1
        }
      }
    })

    let entries = Object.entries(langsCounts)
    if (notSetLang) {
      entries.push([ 'undefined', notSetLang ])
    }

    entries.sort((a, b) => b[1] - a[1])

    const dataEntries = entries.slice(0, 4)
    if (dataEntries.length > 4) {
      dataEntries.push([ 'statistics_users_relation_other_languages', entries.slice(4, entries.length).map(item => item[1]).reduce((a, b) => a + b) ])
    }

    const percent = dataEntries.map(item => item[1]).reduce((a, b) => a + b) / 100

    let data: Array<number> = []
    dataEntries.forEach(([ key, value ]) => {
      data.push(Math.round(value / percent))
    })
    this.locales = {
      series: [
        {
          data: data
        },
      ],
      options: this.getOptions(dataEntries.map(item => this.$te(item[0]) ? this.$t(item[0]).toString() : item[0]))
    }
  }

  getOptions(labels: Array<string>): any {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexLocales
      },
      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 4,
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      legend: {
        position: 'bottom',
        formatter: (label: string, obj: any) => {
          const value = obj.w.config.series[0].data[obj.seriesIndex]
          return `${ label }: ${ value }%`
        }
      },
      labels: labels,
      responsive: [
        {
          breakpoint: 991,
          options: {
            chart: {
              toolbar: {
                show: true,
                tools: {
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true,
                  download: false
                }
              },
              height: 300
            }
          }
        }
      ],
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        }
      },
      yaxis: {
        min: 0,
        max: 100,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: true,
          formatter: (d: string) => {
            return `${ d }%`
          }
        }
      },
      grid: {
        show: true
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function (val: string,obj: any) {
              const label = labels[obj.dataPointIndex]
              const value = obj.series[0][obj.dataPointIndex]
              return `${ label }: ${ value }%`;
            },
          },
          formatter: function () {
            return '';
          },
        },
      },
    }
  }
}
